<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-intro">
      <AppUserAvatar :size="120" />
      <div>
        <h2 class="onboarding-content-title">
          Good stuff,
          <span style="display: inline-block">{{ firstName }}.</span>
        </h2>
      </div>
      <p style="padding: 0 4rem">
        Your answers help us recommend in-budget, not normal trips. Ready for
        more wandering and less wondering?
      </p>
    </div>
    <XButton
      id="submit-login"
      type="large"
      style="margin-top: auto"
      @click="next"
    >
      LET'S GO!
    </XButton>
  </div>
</template>

<script>
import XButton from '../../components/common/global/XButton.vue'
import AppUserAvatar from '../../components-rf/AppUserAvatar.vue'
import { mapActions } from 'vuex'

export default {
  name: 'OnboardingIntro',
  components: { XButton, AppUserAvatar },
  created() {
    this.finish()
  },
  computed: {
    firstName: {
      get() {
        return this.$store.state.OnboardingModule.firstName
      },
    },
  },
  methods: {
    ...mapActions('OnboardingModule', ['finish']),
    next() {
      this.$router.push('/home')
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-intro {
  display: grid;
  justify-items: center;
  gap: 1rem;
  align-self: center;
}
.onboarding-intro-title {
  font-size: 39px;
  font-family: GTSuper;
  line-height: 1.2;
}
</style>
